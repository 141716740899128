.typography {
  width: 90px;
}
.typographyTitle:active {
  background-color: #3486c1 !important ;
}
/* .nav-container {
  overflow-y: scroll;
} */
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 0;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-disabled.MuiAccordion-gutters.css-o71qnz-MuiPaper-root-MuiAccordion-root {
  background-color: #f4f6fa;
  color: black;
  font-weight: bold;
  opacity: 0.9;
}
.hide-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
}

.hide-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  /* background-color: #f4f6fa9f !important;  */
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s, background-color 0.3s;
}

.hide-scrollbar:hover::-webkit-scrollbar-thumb {
  opacity: 1 !important;
}

.hide-scrollbar {
  -ms-overflow-style: none;
}

.hide-scrollbar:hover {
  -ms-overflow-style: scrollbar;
}
.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar:hover {
  scrollbar-width: auto;
}

.disabled .typography {
  color: #ccc;
  cursor: not-allowed;
}
.menu-bars {
  position: relative;
  top: -4%;
}
.nav-menu {
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}
.nav-menu.active {
  left: 0;
  transition: 350ms;
}
.vaultIcon path {
  stroke: #2943d6;
}

.activeIcon path {
  stroke: #2943d6;
}
