.custom-tabs .ant-tabs-nav {
    background: #f0f0f0;
    padding: 3px;
    border-radius: 8px;
    display: inline-flex;
  }
  
  .custom-tabs .ant-tabs-tab {
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
  }
  
  .custom-tabs .ant-tabs-tab-active {
    background: white;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
 :where(.css-dev-only-do-not-override-q4ya04).ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab, :where(.css-dev-only-do-not-override-q4ya04).ant-tabs-card.ant-tabs-top >div>.ant-tabs-nav .ant-tabs-tab {
    border-radius: 8px 8px 8px 8px;
}